<template>
  <div>
    <div
      class="flex flex-col space-y-8 justify-center items-center text-center"
    >
      <div class="relative flex flex-row space-x-5 bg-primary rounded-10 py-2 px-6">
        <div class="rounded-10 px-5 w-28 h-28 grid flex-shrink-0 items-center justify-items-center" :class="{'bg-gray-300': getBankIcon == this.icTf, 'bg-white': getBankIcon != this.icTf}">
          <img :src="getBankIcon" class="h-6" :alt="getBankName" />
        </div>
        <div class="flex flex-col justify-between items-start h-full w-full">
          <div class="text-white text-xs font-normal">Bank name</div>
          <div class="text-white text-base font-semibold">
            {{ getBankName }}
          </div>
          <div class="text-white text-xs font-normal">Account name</div>
          <div class="text-white text-base font-semibold">
            {{ getAccName }}
          </div>
          <div class="text-white text-xs font-normal">Account number</div>
          <div class="text-white text-base font-semibold">
            {{ getAccNum }}
          </div>
        </div>
        <font-awesome-icon icon="fa-solid fa-floppy-disk" class="absolute right-3 top-3 cursor-pointer text-white text-lg" @click="saveData"/>
      </div>
      <div class="text-black">
        <div class="text-base font-semibold">Amount</div>
        <div class="text-3xl font-black">{{$store.state.currency.kode_domestik}}{{ detailTopup.amount }}</div>
        <div class="text-lg font-black" v-show="$store.state.countryCode != 'ID'">Equal To</div>
        <div class="text-3xl font-black" v-show="$store.state.countryCode != 'ID'">Rp{{ nominalReal }}</div>
      </div>
      <div class="relative flex flex-col w-72">
        <div
          class="rounded-t-10 bg-primary text-white text-xs font-semibold py-2 px-5 whitespace-nowrap"
        >
          Transfer Information
        </div>
        <div
          class="rounded-b-10 border border-primary bg-white text-51 text-xs font-semibold py-2 px-5 text-left"
        >
          {{ detailTopup.note }}
        </div>
        <div class="absolute -top-6 left-0 flex text-left space-x-1">
          <img :src="icRp" alt="">
          <div class="font-semibold text-primary text-base">Transfer Fee <span class="font-bold">({{ numberFormat(detailTopup.dataInquiry.switching_fee) }})</span></div>
        </div>
      </div>
    </div>
    <div class="flex space-x-5 justify-end w-full mt-20">
      <div
        @click="cancel"
        class="bg-f5 rounded-10 text-base text-black font-semibold py-2 px-9 cursor-pointer transition ease-in-out duration-300 hover:shadow-lg"
      >
        Cancel
      </div>
      <div
       @click="payment"
        class="flex space-x-3 items-center bg-primary rounded-10 text-base text-white font-semibold py-2 px-9 cursor-pointer transition ease-in-out duration-300 hover:shadow-lg"
      >
        <img :src="icTf" alt="Transfer" />
        <div class="">Transfer</div>
      </div>
    </div>
    <modal-pin ref="modalPin"></modal-pin>
  </div>
</template>

<script>
import { NumberFormat } from "@/utils";
import Vue from 'vue';

export default {
  name: "ToBankFinal",
  components: {
      ModalPin: () => import('@/components/layout/ModalPin.vue'),
  },
  data: () => ({
    icBNI: require("@/assets/icons/ic-bni.png"),
    icBCA: require('@/assets/bca-icon.png'),
    icBRI: require('@/assets/bri-icon.png'),
    icPERMATA: require('@/assets/permata-icon.png'),
    icSave: require("@/assets/icons/ic-save.svg"),
    icTf: require("@/assets/icons/ic-tf-white.svg"),
    icRp: require("@/assets/icons/ic-rp.svg"),
  }),
  methods: {
    numberFormat: num => NumberFormat(num),
    cancel() {
      return this.$router.go(-1);
    },
    async payment(){
        const pin = await this.$refs.modalPin.open({
          button: 'OK'
        });

        if(pin == null){
            return;
        }

      try {
        this.$store.commit('setOverlayLoading', true);
        const res = await this.$http.post(`${this.$apiTripweWeb}/cashless-to-bank/transfer`, {
          "reference_no":this.detailTopup.dataInquiry.reference_no,
          "pin": pin,
        });
        this.dataPayment = res.data;
        if(res.data.status != "200"){
          throw res.data.message;
        }else 
        if(res.data.status == "200"){
          this.$toasted.global.success('Your transaction has been processed');
        }
      } catch (error) {
        this.$toasted.global.error(error);
      } finally {
        this.$store.commit('setOverlayLoading', false);
        this.cancel();
      }
    },
    async saveData(){
      this.$store.commit('setOverlayLoading', true);
      
      var fData = new FormData();
      fData.append("txtRek", this.getAccNum);
      fData.append("txtNama", this.getAccName);
      fData.append("txtKode", this.detailTopup.bank);
      fData.append("txtNamaBank", this.getBankName);
      fData.append("txtId", this.$store.state.user?.id_user);
      fData.append("mobile", "android");

      const res = await this.$http.post(`${this.$apiTripwe}/ic/jetski-android-new/insert_daftar_transfer_bank_v16.php`,fData);
      if(res.data == 'success'){
        this.$toasted.global.success('Data transaction has been saved');
      }
      this.$store.commit('setOverlayLoading', false);
    }
  },
  created() {

  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    detailTopup() {
      return this.$route.params.data;
    },
    getBankName() {
      return this.detailTopup?.dataInquiry?.bank_name ?? '-';
    },
    getAccName() {
      return this.detailTopup?.dataInquiry?.account_name ?? '-';
    },
    getAccNum() {
      return this.detailTopup?.dataInquiry?.account_no ?? '-';
    },
    getBankIcon() {
      if(this.detailTopup?.dataInquiry?.bank_code == "014"){
        return this.icBCA;
      }
      if(this.detailTopup?.dataInquiry?.bank_code == "002"){
        return this.icBRI;
      }
      if(this.detailTopup?.dataInquiry?.bank_code == "013"){
        return this.icPERMATA;
      }
      return this.icTf;
    },
    nominalReal() {
      var val = Vue.filter('exchangeCurrency')(this.detailTopup.amount);
      return val;
    }
  },
};
</script>
